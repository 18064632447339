import React from 'react';
import { graphql } from 'gatsby';
import Image from '../../components/Image';

import Hero, { Tagline, Text, Title } from '../../sections/hero/hero';
import Layout from '../../components/layout/layout';
import CallToAction from '../../sections/CallToAction';
import { Grid, Stack, Box, Link, Typography } from '@mui/material';
import Section from '../../components/section';
import { ButtonSmall } from '../../components/button';

const Partner = (props) => {
  const backgroundImage = props.data.background.childImageSharp.gatsbyImageData;
  const partner = props.data.partner.nodes;

  const PartnerItem = ({
    slug,
    summary: { summary },
    categories,
    name,
    logoLight,
    logoDark,
  }) => (
    <Grid item xs={12} md={4}>
      <Stack>
        <Link to={`/partner/${slug}/`}>
          <Image mb={2} srcDark={logoDark} srcLight={logoLight} alt={name} />
        </Link>
        <Box>
          <Typography variant="body1" gutterBottom>
            {categories.join(', ')}
          </Typography>
          <Typography variant="h5" gutterBottom>
            {name}
          </Typography>
          <Typography variant="body1" gutterBottom marginBottom={2}>
            {summary}
          </Typography>
          <ButtonSmall href={`/partner/${slug}/`}>Mehr erfahren</ButtonSmall>
        </Box>
      </Stack>
    </Grid>
  );

  return (
    <Layout
      title="Digital-Beratung aus Berlin"
      pathname={props.location.pathname}
    >
      <Hero backgroundImage={backgroundImage} backgroundColor="#EFEFEF">
        <Tagline>Partner-Netzwerk</Tagline>
        <Title>Unsere Partner stehen euch zur Verfügung</Title>
        <Text>
          Durch unsere Arbeit kennen wir viele Dienstleister der
          Versicherungsbranche.
        </Text>
        <Text>
          Damit ihr wisst, mit wem ihr die Herausforderungen der Zukunft angehen
          solltet, bringen wir euch mit den richtigen Partnern zusammen.
        </Text>
      </Hero>
      <Section>
        <Grid container columnSpacing={2} rowSpacing={4}>
          {partner
            .sort((a, b) => {
              a = a.name.toLowerCase();
              b = b.name.toLowerCase();
              if (a === b) return 0;
              if (a > b) return 1;
              return -1;
            })
            .map((item) => PartnerItem(item))}
        </Grid>
      </Section>
      <CallToAction
        title="Wir stellen euch die richtigen Partner vor"
        text="Vereinbart mit Jonas einen Termin und besprecht mit ihm eure Herausforderungen. Gemeinsam schaut ihr, welcher Partner für euch passend sein kann."
        label="Termin vereinbaren"
        href="https://calendar.app.google/1EZAsChBVHFGvZqG7"
      />
    </Layout>
  );
};

export const query = graphql`
  query MyQuery {
    background: file(relativePath: { eq: "backgrounds/bg-consulting.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }

    partner: allContentfulPartner {
      nodes {
        slug
        targetAudience {
          targetAudience
        }
        summary {
          summary
        }
        partnerOffering {
          partnerOffering
        }
        name
        categories
        logoLight {
          url
          gatsbyImageData(
            width: 500
            height: 300
            placeholder: BLURRED
            resizingBehavior: PAD
          )
          mimeType
        }
        logoDark {
          url
          gatsbyImageData(
            width: 500
            height: 300
            placeholder: BLURRED
            resizingBehavior: PAD
          )
          mimeType
        }
      }
    }
  }
`;

export default Partner;
